import React from 'react';
import styled from 'styled-components';

const DisclaimerDiv = styled.div`
  z-index: 50;
  background-color: #f8f7f3;
  left: 50%;
  transform: translate(-50%);
  position: fixed;
  padding: 0.875rem 1.5rem;
  border-radius: 0.9375rem;
  bottom: 1.1rem;
  width: calc(70% - 2.4rem);

  @media (min-width: 768px) {
    bottom: 40px;
    max-width: 250px;
  }
`

const DisclaimerFlex = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DivOne = styled.div`
  color: #94938d;
  font-size: 12px;
  line-height: 1.21;

& span {
    text-decoration: none;
    color: #94938d;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(133, 133, 133, .4);
    background-color: transparent;
  }
}
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 0 0 2px;
  border-bottom: 1px solid rgba(133, 133, 133, .4);
  display: block;
  margin-left: 1.25rem;
  color: #080808;
  font-size: 12px;
  font-family: inherit;
  cursor: pointer;
`

const Disclaimer = ({open, onClose}) => {
  if (!open) return null
  return (
    <DisclaimerDiv>
      <DisclaimerFlex>
        <DivOne>
          Place bets &nbsp;<span>responsibly.</span>
        </DivOne>
        <Button  onClick={onClose}>Close</Button>
      </DisclaimerFlex>
    </DisclaimerDiv>
  )
}

export default Disclaimer;