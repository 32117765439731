import React, {useState} from "react";

const History = () => {
  return (
    <div>
      History...
    </div>
  )
}

export default History;