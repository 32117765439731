import React from 'react';
import styled, { keyframes } from 'styled-components';

const SkeletonCardContainer = styled.div`
  // padding: 24px;
  width: 100%;
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const SkeletonImage = styled.div`
  width: 100%;
  height: 240px;
  background-color: #ebebeb;
  border-radius: 16px;
  margin-bottom: 24px;
  animation: shimmer 1s infinite;

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
`;

const FlexSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SkeletonText = styled.div`
  width: ${({ width }) => width || '100%'};
  height: 16px;
  background-color: #ebebeb;
  border-radius: 4px;
  margin-bottom: 12px;
  animation: shimmer 1s infinite;

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
`;

const SkeletonPfp = styled.div`
  width: 40px;
  height: 40px;
  background-color: #ebebeb;
  border-radius: 50%;
  margin-bottom: 12px;
  animation: shimmer 1s infinite;

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
`

const SkeletonCard = () => {
  return (
    <SkeletonCardContainer>
      <SkeletonImage />
      <FlexSkeleton>
        <SkeletonText width="50%" />
        <SkeletonPfp width="15%" />
      </FlexSkeleton>
      <SkeletonText width="50%" />
      <SkeletonText width="65%" />
      <SkeletonText width="65%" />
      <SkeletonText width="30%" />
    </SkeletonCardContainer>
  );
};

export default SkeletonCard;