import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10%, 90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const NotificationWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--color-white);
  color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #808080;
  border-radius: 2rem;
  // padding-left: 1rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: ${fadeInOut} 3s forwards;
`;

const NotificationMessage = ({ message, show }) => {
  return (
    show && (
      <NotificationWrapper>
        {message}
      </NotificationWrapper>
    )
  );
};

export default NotificationMessage;