import React, {useEffect, useState} from "react";
import styled from "styled-components";
import axios from 'axios';
import { io } from 'socket.io-client';
import { BASE_URL, SOCKET_URL } from "../requestMethods";
import messageSentSound from './message_sent.mp3';
import JazziconProfile from "./JazziconProfile";

const TabC1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 24px;
`

const CommentBox = styled.div`
  align-items: center;
  padding-bottom: 1.6rem;
  display: flex;
`

const CommentDiv = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
`

const CInputDiv = styled.div`
  border-radius: 8px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  border-color: #E0E0E0;
  display: flex;
`

const InputEl = styled.input`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease 0s;
  cursor: text;
  outline: none;
  color: var(--color-primary);
  font-size: inherit;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  border: none;
  height: 100%;
  box-sizing: border-box;
  background: none;
  font-family: inherit;
  flex: 1 1 0%;
  font-weight: 400;
`

const InputButton = styled.button`
  background: transparent;
  height: 44px;
  font-size: 1.4rem;
  border: none;
  color: var(--color-primary);
  opacity: 0.5;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: inherit;
  user-select: none;
  padding: 0 1.2rem;
`

const UsercBox = styled.div`
  margin-top: 1rem;
  display: flex;
`

const UserCFlex = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`

const UserCpfpa = styled.div`
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  border-radius: 50%;
  background-color: ${props => props.color};
  display: inline-block;
  margin-right: 8px;
`

const UserCBox = styled.div`
  flex: 1 1 0%;
  margin-left: var(--space-4);
  flex-direction: column;
  width: 90%;
  white-space: normal;
  overflow: hidden;
  text-overflow: clip;
  display: flex;
`

const UserCB = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const UserCB1 = styled.div`
  align-items: center;
  display: flex;
`

const UserName = styled.p`
  font-size: 14px;
  text-overflow: ellipsis;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  transition: textDecoration 0.1s ease 0s;
`

const Timestamp = styled.p`
  margin-left: .6rem;
  font-size: 1.2rem;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`

const UserCText = styled.div`
  display: flex;
`

const UserCTextP = styled.p`
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 14px;
  max-width: 100%;
  line-height: 21px;
  font-weight: 400;
`

const socket = io(SOCKET_URL);
socket.on('connect', () => {
  console.log('connected to websocket server');
});
socket.on('disconnect', () => {
  console.log('disconnected from websocket server');
});

const Comments = ({betId, currentWallet}) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    fetchComments();
    socket.on('newComment', (comment) => {
      if (comment.betId === betId) {
        setComments((prevComments) => [comment, ...prevComments]);
      }
    });
    return () => {
      socket.off('newComment');
    };
  }, [betId]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/comments/${betId}`);
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching comments', error);
    }
  };

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = async () => {
    if (newComment == '') {
      alert("Please type a comment!");
      return;
    }
    if (!currentWallet) {
      alert("Please connect a wallet to send comments");
      return;
    }
    try {
      await axios.post(`${BASE_URL}/comments`, { betId, user: currentWallet, message: newComment });
      const audio = new Audio(messageSentSound);
      audio.play();
      setNewComment('');
    } catch (error) {
      console.error('Error posting comment', error);
    }
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const generateColorFromAddress = (address) => {
    let hash = 0;
    for (let i = 0; i < address.length; i++) {
      hash = address.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  return (
    <TabC1>
      <CommentBox>
        <CommentDiv>
          <CInputDiv>
            <InputEl placeholder="Leave a comment" autoComplete="disabled" 
            type="text"
            value={newComment}
            onChange={handleInputChange}
            ></InputEl>
          <InputButton onClick={handleSubmit}>Send</InputButton>
          </CInputDiv>
        </CommentDiv>
      </CommentBox>
      {comments?.map(comment => (
        <UsercBox key={comment._id}>
        <UserCFlex>
          <JazziconProfile address={comment.user} width={30} height={30} />
          <UserCBox>
            <UserCB>
              <UserCB1>
                <a>
                  <UserName>{formatWalletAddress(comment.user)}</UserName>
                </a>
                <Timestamp>{new Date(comment.createdAt).toLocaleTimeString()}</Timestamp>
              </UserCB1>
            </UserCB>
            <UserCText>
              <UserCTextP>{comment.message}</UserCTextP>
            </UserCText>
          </UserCBox>
        </UserCFlex>
      </UsercBox>
      ))}
    </TabC1>
  )
}

export default Comments;