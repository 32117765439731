import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const LoadingBlack = () => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "0",
    }}>
      <ThreeDots
        height="10" 
        width="25" 
        radius="9"
        color="#101010" 
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  )
}

export default LoadingBlack