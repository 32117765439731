import { Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/Home';
import { useEffect, useState } from 'react';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';

const App = () => {
  const [currentWallet, setCurrentWallet] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        console.log('Metamask not detected');
        return;
      }
      let chainId = await ethereum.request({ method: 'eth_chainId'});

      const maticChainId = 137;
      if (chainId == maticChainId) {
        setCurrentNetwork(true);;
      }

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

      setCurrentWallet(accounts[0]);
    } catch (error) {
      console.log('Error connecting to metamask', error)
    }
  }

  const checkCorrectNetwork = async () => {
    const { ethereum } = window;
    let chainId = await ethereum.request({ method: 'eth_chainId' });

    const maticChainId = 137;

    if (chainId !== maticChainId) {
      setCurrentNetwork(false)
    } else {
      setCurrentNetwork(true)
    }
  }

  // useEffect(() => {
  //   connectWallet();
  // });

  return (
    <Routes>
     <Route exact path='/' element={<Home currentNetwork={currentNetwork} currentWallet={currentWallet} connectWallet={connectWallet} toggleTheme={toggleTheme} />} />
     <Route exact path='/profile' element={<Profile currentNetwork={currentNetwork} currentWallet={currentWallet} connectWallet={connectWallet} theme={isDarkMode ? 'light' : 'dark'} />} />
     <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
