import React, { useState, useEffect } from 'react';
import {styled} from "styled-components";
import Comments from './Comments';
import History from './History';
import VolumeBars from './VolumeBars';
import { ethers, BigNumber } from 'ethers';
import { formatDistanceToNow } from 'date-fns';
import { contractAddr, polygonExplorerAddr, usdc_proxyAddr, mock_usdc } from '../config';
import Stake from '../utils/abi.json';
import USDC_PROXY_ABI from '../utils/usdc-proxyAbi.json';
// import USDC_ABI from '../utils/usdc-abi.json';
import USDC_ABI from '../utils/mockUsdcs.json';
import { Link } from 'react-router-dom';
import NotificationMessage from './NotificationMessage';
import LoadingWhite from './LoadingWhite';
import LoadingBlack from './LoadingBlack';

const OuterDiv = styled.div`
  display: flex;
  max-width: var(--page-layout);
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin: 4.5rem auto;

  @media (mint-width: 1018px) {
    width: 100%;
    height: 100%;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media (min-width: 1018px) {
    gap: 1.6rem;
    padding-right: 0.8rem;
    margin-top: 0.8rem;
  }
`

const GridHolder = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 8px;
  border: 1px solid #e4e4e455;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 6px 12px rgba(0, 0, 0, 0.02);
  min-height: 170px;
  padding-top: 12px;
  transition: box-shadow 0.1s ease 0s, background-color 0.1s ease 0s;
  overflow: hidden;

  @media (max-width: 450px) {
    margin: 1rem;
  }
`

const GrUp = styled.div`
  display: flex;
  // height: 42px;
  // min-height: 42px;
  // max-height: 42px;
  width: 100%;
  z-index: 3;
  align-items: center;
  position: relative;
  gap: .8rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`

const GrUp1 = styled.div`
  border-radius: 4px;
  position: relative;
`
const GrUp1ImageDiv = styled.div`
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const GrUp2 = styled.div`
  flex: 1 1 0%;
  min-width: 0px;
  justify-content: space-between;
  cursor: default;
  display: flex;

  & a {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    min-width: 0px;
    color: inherit;
  }

  & p {
    padding-right: 0px;
    width: auto;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 700 !important;
    margin: 0px;
    min-width: 0px;
    font-size: 1.4rem;
    cursor: pointer;
    text-decoration: underline 2px rgba(0, 0, 0, 0);
    padding-left: 0px;
    transition: text-decoration-color 0s ease 0s, opacity 0.1s ease 0s;
    line-height: 20px !important;
  }
`

const BetState = styled.div`
  font-size: 1.2rem;
  color: var(--color-primary);
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
`

const BBDiv = styled.div`
  background: #27AE601A;
  padding: .1rem;
`

const GrDown = styled.div`
  display: flex;
  position: relative;
  // overflow: hidden;
  // height: 100%;
  width: 100%;
`

const GrDownFlex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  gap: .8rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: .8rem;
  z-index: 1;
  opacity: 1;
  transform: translateY(0px) translateZ(0px);
`

const OptionsParagraph = styled.p`
  margin-left: .5rem;
`

const GrOptionFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
  gap: 1.2rem;
  height: 62px;
`

const GrOptionFlex1 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  height: 62px;
  flex-wrap: wrap;
`

const ButtonYes = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: .4rem;
  height: 36px;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  line-height: 20px;
  font-variant-numeric: tabular-nums;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #27AE601A;
  border: none;
  color: #27AE60;
  transition: background-color 0.075s ease-in-out 0s, color 0.075s ease-in-out 0s;
  flex: 1 1 0%;
  touch-action: none;
  font-weight: 600;
  font-size: 1.4rem;

  &:disabled {
    cursor: not-allowed;
  }
`

const ButtonYesFlex = styled.div`
  display: flex;
  align-items: center;
`

const ButtonNo = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: .4rem;
  height: 36px;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  line-height: 20px;
  font-variant-numeric: tabular-nums;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #EB57571A;
  border: none;
  color: #E64800;
  transition: background-color 0.075s ease-in-out 0s, color 0.075s ease-in-out 0s;
  flex: 1 1 0%;
  touch-action: none;
  font-weight: 600;
  font-size: 1.4rem;

  &:disabled {
    cursor: not-allowed;
  }
`

const ButtonNoFlex = styled.div`
  display: flex;
  align-items: center;
`

const GrStatsDiv = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.2rem;
  color: inherit;
  transition: all 0.2s ease 0s;
  position: relative;
`

const GrStatsFlex = styled.div`
  flex-direction: row;
  gap: 0.8rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  display: flex;
`

const Published = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
`

const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PooledSpan = styled.span`
  color: var(--color-primary);
`

const Pooled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const IconSpan = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  border: none;
  margin: 0px 0px 0.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  
  & span {
    color: var(--color-primary);
    fill: var(--color-primary);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }
`

const Slider = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: white;
  transform: ${({ isvisible }) => (isvisible ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  width: 100%;
  max-width: 570px;
  overflow-y: auto;

  @media (min-width: 768px) {
    width: 80%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${({ isvisible }) => (isvisible ? 'visible' : 'hidden')};
  opacity: ${({ isvisible }) => (isvisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
`;

const SlHeader = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  gap: 1.6rem;
  grid-template-columns: auto 1fr auto;
  min-height: 84px;
  display: grid;

  & h1 {
    max-width: 400px;
    font-size: 1.8rem;
    
    & span {
      overflow-wrap: break-word;
    }
  }
`

const SlImageHolder = styled.div`
  max-width: 70px;
  max-height: 70px;
  min-width: 50px;
`

const MoreDetailsDiv = styled.div``

const MoreDets1 = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`

const MDLeft = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  & p {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
    margin-right: 1.6rem;
  }

  & div {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    gap: 6px;
    white-space: nowrap;
    margin-right: 1.6rem;
    align-items: center;
  }
`

const MDRight = styled.div`
  display: flex;
  gap: 4px;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--color-white);
    color: var(--color-primary);
    padding: 0px;
    transition: all 0.2s ease 0s;
    overflow: visible;
    text-transform: none;

    & span {
      width: 20px;
      height: 20px;
    }
  }
`

const SlImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const CloseButonDiv = styled.div`
  height: clamp(1.25rem, .8rem + 2vw, 2.5rem);
  width: clamp(1.25rem, .8rem + 2vw, 2.5rem);
  min-width: 2rem;

  & span {
    width: 1.8rem;
    height: 1.8rem;
    display: block;
    vertical-align: middle;
    font-family: inherit;
  }
`

const TabButtons = styled.div`
  display: flex;
  border-bottom: 1px solid #F2F2F2;
  margin-top: 3.2rem;
`;

const TabButton = styled.button`
  margin-right: 3.2rem;
  padding-bottom: 1.5rem;
  font-family: inherit;
  color: #2b2b2b;
  font-size: 1.6rem;
  border-bottom: 2px solid #2b2b2b;
  font-weight: 600;
  outline: none;
  border: none;
  border-bottom: ${({ isActive }) => (isActive ? '1px solid #2b2b2b' : '')};
  cursor: pointer;
  background: none;
`;

const TabContent = styled.div`
  width: 100%;
  padding-top: 2.4rem;

  overflow: hidden;
`;

const BLogs = styled.div`
  margin-top: 3.2rem;
`

const Blogs1 = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  display: flex;

  & span {
    font-family: inherit;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.75;
  }
`

const Blogs2 = styled.div`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #F2F2F2;
`

const Blogs2f = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.4rem;
`

const WAddrDiv = styled.div`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #F2F2F2;
  flex-wrap: wrap;
  gap: 1rem;
`

const WAddr2f = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
`

const Blogs2p1 = styled.div`
  margin: 0px;
  transition: all 0.2s ease 0s;
  font-weight: 400;

  & span {
    color: #828282;
  }
`

const Blogs2a = styled.a`
  text-decoration: underline;
  color: #828282;

  & p {
    cursor: pointer;
    white-space: nowrap;
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-weight: 400;
  }
`

const BRulesDiv = styled.div`
  margin-top: 3.2rem;
`

const BRTopic = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 1.5rem;
  display: flex;

  & p {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-size: 2rem;
    line-height: 1.75;
  }
`

const BrCopy = styled.div`
  max-width: 784px;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  overflow-wrap: break-word;
  font-size: 1.4rem;
`

const BrResolver = styled.div`
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  display: block;
`

const ResolverFlex = styled.div`
  display: flex;
  
  @media (min-width: 1018px) {
    flex-direction: row;
    gap: 1.4rem;
  }
`

const ResolverHolder = styled.div`
  display: flex;
  padding-right: 2.4rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

const AddressDiv = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  & p {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-weight: 400;
    font-size: 1.4rem;
    color: #828282;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & a {
    justify-content: flex-start;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    color: var(--color-purple);
    display: flex;
    align-items: center;
    border: none;
    border-radius: 7px;
    transition: all 0.2s ease 0s;
    user-select: none;
    font-weight: 500;
  }
`

const PadDiv = styled.div`
  padding-bottom: 2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;

  & canvas {
    font-family: inherit;
  }
`

const VolumeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
`

const InputDiv = styled.div`
  position: relative;
  align-items: center;
  max-width: 100%;
  display: flex;

  & input {
    font-family: inherit;
    margin: 0;
    overflow: visible;
    display: flex;
    align-items: center;
    transition: color 0.2s ease 0s;
    text-align: center;
    flex: 1 1 0%;
    border-color: #E0E0E0;
    border-width: 1px;
    border-style: solid;
    font-size: 1.4rem;
    width: 100%;
    height: 48px;
    align-self: center;
    padding: 1.6rem;
    background: var(--color-white);
    color: inherit;
    outline: none;
    border-radius: .8rem;
  }

  & select {
    font-family: inherit;
    margin: 0;
    overflow: visible;
    display: flex;
    align-items: center;
    transition: color 0.2s ease 0s;
    text-align: center;
    flex: 1 1 0%;
    border-color: #E0E0E0;
    border-width: 1px;
    border-style: solid;
    font-size: 1.4rem;
    width: 100%;
    height: 48px;
    align-self: center;
    padding: 1.6rem;
    background: var(--color-white);
    color: inherit;
    outline: none;
    border-radius: .8rem;
  }
`

const ToggleDivLeft = styled.div`
  position: absolute;
  align-items: center;
  align-self: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  color: #808080;
  width: 100%;
  cursor: text;
  transition: all 0.2s ease 0s;
  display: flex;
  place-self: center left;
  display: flex;
  justify-content: left;
  left: 1.2rem;
  width: fit-content;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    transition: all 0.2s ease 0s;
    font-size: 1.2rem;
    font-family: inherit;
    letter-spacing: 0.3px;
    user-select: none;
    font-weight: 500;

    padding: 0px;
    margin: 0px;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: #F2F2F2;
    cursor: pointer;
  }
`

const ToggleDivRight = styled.div`
  position: absolute;
  align-items: center;
  align-self: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  color: #808080;
  width: 100%;
  cursor: text;
  transition: all 0.2s ease 0s;
  
  display: flex;
  place-self: center right;
  display: flex;
  justify-content: right;
  right: 1.2rem;
  width: fit-content;

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    transition: all 0.2s ease 0s;
    font-size: 1.2rem;
    font-family: inherit;
    letter-spacing: 0.3px;
    user-select: none;
    font-weight: 500;

    padding: 0px;
    margin: 0px;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: #F2F2F2;
    cursor: pointer;
  }
`

const BetGrid = ({bets, currentWallet, publisher, updateBet}) => {
  const [activeElement, setActiveElement] = useState(null);
  const [activeTab, setActiveTab] = useState('comments');
  const [value, setValue] = useState(0);
  const [yesVolume, setYesVolume] = useState(0);
  const [noVolume, setNoVolume] = useState(0);
  const [betId, setBetId] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [option, setOption] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [volumeLoading, setVolumeLoading] = useState(false);

  const convertBigNumberToDate = (bigNumber) => {
    const bn = BigNumber.isBigNumber(bigNumber) ? bigNumber : BigNumber.from(bigNumber);
    const timestamp = bn.toNumber();
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  const decimals = 6;

  const formatVolume = (amount) => {
    const amountInSmallestUnit = ethers.BigNumber.from(amount);
    const value = ethers.utils.formatUnits(amountInSmallestUnit, decimals);
    return `$${Number(value).toFixed(2)}`;
  };

  const getRemainingTime = (createdTime, duration) => {
    const now = Math.floor(Date.now() / 1000);
    const expirationTime = createdTime + duration;
    const remainingTime = expirationTime - now;
  
    if (remainingTime <= 0) return "Expired";
  
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
  
    return `${minutes} min ${seconds} sec`;
  };
  

  // const formatTime = (createdTime, duration) => {
  //   const createdTimeInSeconds = ethers.BigNumber.from(createdTime).toNumber();
  //   const durationInSeconds = ethers.BigNumber.from(duration).toNumber();
  //   const expirationTimeInSeconds = createdTimeInSeconds + durationInSeconds;
  //   const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  
  //   return currentTimeInSeconds > expirationTimeInSeconds ? "Expired" : "Running";
  // };

  const formatTime = (createdTime, duration) => {
    const createdTimeInSeconds = ethers.BigNumber.from(createdTime).toNumber();
    const durationInSeconds = ethers.BigNumber.from(duration).toNumber();
    const expirationTimeInSeconds = createdTimeInSeconds + durationInSeconds;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  
    const remainingTimeInSeconds = expirationTimeInSeconds - currentTimeInSeconds;
  
    if (remainingTimeInSeconds <= 0) return "Expired";
  
    const hours = Math.floor(remainingTimeInSeconds / 3600);
    const minutes = Math.floor((remainingTimeInSeconds % 3600) / 60);
  
    return `${hours} hrs ${minutes} mins`;
  };
  

  const formatPublishTime = (timestamp) => {
    const timestampInSeconds = ethers.BigNumber.from(timestamp).toNumber();
    const date = new Date(timestampInSeconds * 1000);
    return formatDistanceToNow(date, { addSuffix: true });
  };  

  const handleCopy = async () => {
    const currentUrl = window.location.href;
    try {
      await navigator.clipboard.writeText(currentUrl);
      setMessage("URL copied");
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleBetNotifId = async(text) => {
    setMessage(text);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  }

  const handleInputChange = (e) => {
    const val = e.target.value;
    if (/^\d*$/.test(val)) {
      setValue(Number(val));
      setBetAmount(Number(val));
    }
  };

  const handleOptionChange = (e) => {
    const val = e.target.value;
    setOption(val);
  }

  const incrementValue = () => {
    setValue(prevValue => prevValue + 1);
    setBetAmount(prevValue => prevValue + 1);
  };

  const decrementValue = () => {
    setValue(prevValue => (prevValue > 0 ? prevValue - 1 : 0));
    setBetAmount(prevValue => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const handleHeadingClick = (details) => {
    setActiveElement(details);
    queryBetVolumes(details.id);
  };

  const handleCloseClick = () => {
    setActiveElement(null);
    setValue(0);
  };

  const renderContent = (id) => {
    switch (activeTab) {
      case 'comments':
        return <Comments betId={id} currentWallet={currentWallet} message={message} show={showNotification}  />;
      case 'history':
        return <History />;
      default:
        return <Comments betId={id} currentWallet={currentWallet} message={message} show={showNotification} />;
    }
  };

  const queryBetVolumes = async(id) => {
    try {
      setVolumeLoading(true);
      const provider = new ethers.providers.JsonRpcProvider(`https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA}`);
      const StakeContract = new ethers.Contract(contractAddr, Stake.abi, provider);

      let retYesVolumes = await StakeContract.getSideTotal(id, "YES");
      let retNoVolumes = await StakeContract.getSideTotal(id, "NO");

      const tempYesVolume = parseFloat(ethers.utils.formatUnits(retYesVolumes, 6));
      const tempNoVolume = parseFloat(ethers.utils.formatUnits(retNoVolumes, 6));

      setYesVolume(tempYesVolume);
      setNoVolume(tempNoVolume);
      setVolumeLoading(false);
    } catch(error) {
      setVolumeLoading(false);
      console.log(error);
    }
  }

  const USDC_DECIMALS = 6;
  const MAX_UINT256 = ethers.constants.MaxUint256;

  const placeNewBet = async (id, betSide, optionsL) => {
    if (betAmount <= 0) {
      handleBetNotifId('Input a bet amount');
      console.log("Input a bet amount");
      return;
    }

    if (!currentWallet) {
      handleBetNotifId('Please connect wallet');
      console.log("Please connect wallet");
      return;
    }

    let betItem = {
      'betId': id,
      'amount': ethers.utils.parseUnits(betAmount.toString(), USDC_DECIMALS),
      'side': betSide,
      'subject': option
    };

    try {
      const {ethereum} = window

      if(ethereum) {
        setLoading(true);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const StakeContract = new ethers.Contract(
          contractAddr,
          Stake.abi,
          signer
        )

        // const USDCContract = new ethers.Contract(usdc_proxyAddr, USDC_ABI.abi, signer);
        if (optionsL > 0) {
          if (betItem.subject == "") {
            alert("Please select an option");
            return;
          }
          const USDCContract = new ethers.Contract(mock_usdc, USDC_ABI.abi, signer);
        
          const balance = await USDCContract.balanceOf(signer.getAddress());
          const allowance = await USDCContract.allowance(signer.getAddress(), contractAddr);

          if (allowance.lt(betItem.amount)) {
            const approveTxn = await USDCContract.approve(contractAddr, MAX_UINT256);
            await approveTxn.wait();
          }

          let newBetTxn = await StakeContract.placeBet(betItem.betId, betItem.amount, betItem.side, betItem.subject);
          const betReceipt = await newBetTxn.wait();
          setLoading(false);

          if (betReceipt.status === 1) {
            handleBetNotifId('Bet placed');
            queryBetVolumes(betItem.betId);
            let updatedBet = await StakeContract.getBet(betItem.betId);
            updateBet(updatedBet);
            setBetAmount(0);
            setValue(0);
          } else {
            setBetAmount(0);
            setValue(0);
            handleBetNotifId('Bet failed');
          }
        } else {
          const USDCContract = new ethers.Contract(mock_usdc, USDC_ABI.abi, signer);
        
          const balance = await USDCContract.balanceOf(signer.getAddress());
          const allowance = await USDCContract.allowance(signer.getAddress(), contractAddr);

          if (allowance.lt(betItem.amount)) {
            const approveTxn = await USDCContract.approve(contractAddr, MAX_UINT256);
            await approveTxn.wait();
          }

          let newBetTxn = await StakeContract.placeBet(betItem.betId, betItem.amount, betItem.side, "");
          const betReceipt = await newBetTxn.wait();
          setLoading(false);

          if (betReceipt.status === 1) {
            handleBetNotifId('Bet placed');
            queryBetVolumes(betItem.betId);
            let updatedBet = await StakeContract.getBet(betItem.betId);
            updateBet(updatedBet);
            setBetAmount(0);
            setValue(0);
          } else {
            setBetAmount(0);
            setValue(0);
            handleBetNotifId('Bet failed');
          }
        }
      } else {
        console.log("Ethereum object doesn't exist!");
        setLoading(false);
        setValue(0);
      }
    } catch(error) {
      setValue(0);
      console.log("Error submitting bet", error);
      setLoading(false);
      if (error.toString().includes('Already bet on this')) {
        handleBetNotifId('Failed: Already bet on this');
      }
    }
  }

  const pushBetNo = (e, betId, optionsL) => {
    e.preventDefault();
    
    placeNewBet(betId, "NO", optionsL);

    setBetId(0);
    setBetAmount(0);
  };

  const pushBetYes = (e, betId, optionsL) => {
    e.preventDefault();

    placeNewBet(betId, "YES", optionsL);

    setBetId(0);
    setBetAmount(0);
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <OuterDiv>
      <Grid>
      {bets?.map((bet) => (
        <GridHolder key={bet.id.toNumber()}>
          <GrUp>
            <GrUp1>
              <GrUp1ImageDiv onClick={() => handleHeadingClick({
                id: bet.id,
                title: bet.text,
                image: bet.image,
                createdTime: bet.createdTime,
                volume: bet.volume,
                duration: bet.duration,
                outcome: bet.outcome,
                publishTime: bet.publishTime,
                eligibleAddresses: bet.eligibleAddresses,
                optionsLen: bet.options.length,
                options: bet.options,
                subject: bet.winningSubject
              })}>
                <img src={bet.image} />
              </GrUp1ImageDiv>
            </GrUp1>
          <GrUp2 onClick={() => handleHeadingClick({
            id: bet.id,
            title: bet.text,
            image: bet.image,
            createdTime: bet.createdTime,
            volume: bet.volume,
            duration: bet.duration,
            outcome: bet.outcome,
            publishTime: bet.publishTime,
            eligibleAddresses: bet.eligibleAddresses,
            optionsLen: bet.options.length,
            options: bet.options,
            subject: bet.winningSubject
          })}>
            <a>
              <p>{bet.text}</p>
              <BetState>
                <span>
                  {bet.publishTime.toNumber() != 0 ? <>Outcome: {bet.outcome}</> : <>{formatTime(bet.createdTime, bet.duration)}</>}
                </span>
                {bet.winningSubject != "" ? <span>
                  {bet.winningSubject}
                </span> : <></>}
                {bet.eligibleAddresses.length > 0 ? (
                  <BBDiv>
                    <span>Buddy bet</span>
                  </BBDiv>
                ) : <></>}
              </BetState>
            </a>
          </GrUp2>
        </GrUp>
        <GrDown>
          <GrDownFlex>
            {
              bet.options.length > 0 ? 
              (
                <>
                  <OptionsParagraph>{bet.options.length} options</OptionsParagraph>
                </>
              ) : 
              (
                <GrOptionFlex>
              <ButtonYes onClick={() => handleHeadingClick({
                id: bet.id,
                title: bet.text,
                image: bet.image,
                createdTime: bet.createdTime,
                volume: bet.volume,
                duration: bet.duration,
                outcome: bet.outcome,
                publishTime: bet.publishTime,
                eligibleAddresses: bet.eligibleAddresses,
                optionsLen: bet.options.length,
                options: bet.options,
                subject: bet.winningSubject
              })}>
                <ButtonYesFlex>
                  <span>Yes</span>
                </ButtonYesFlex>
              </ButtonYes>
              <ButtonNo onClick={() => handleHeadingClick({
                id: bet.id,
                title: bet.text,
                image: bet.image,
                createdTime: bet.createdTime,
                volume: bet.volume,
                duration: bet.duration,
                outcome: bet.outcome,
                publishTime: bet.publishTime,
                eligibleAddresses: bet.eligibleAddresses,
                optionsLen: bet.options.length,
                options: bet.options,
                subject: bet.winningSubject
              })}>
                <ButtonNoFlex>
                  <span>No</span>
                </ButtonNoFlex>
              </ButtonNo>
            </GrOptionFlex>
              )
            }
            <GrStatsDiv>
              <GrStatsFlex>
                <Published>
                  <RowDiv>
                    <IconSpan>
                      <span>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g fillOpacity=".9"><path d="M255.8 48C141 48 48 141.2 48 256s93 208 207.8 208c115 0 208.2-93.2 208.2-208S370.8 48 255.8 48zm.2 374.4c-91.9 0-166.4-74.5-166.4-166.4S164.1 89.6 256 89.6 422.4 164.1 422.4 256 347.9 422.4 256 422.4z"></path><path d="M266.4 152h-31.2v124.8l109.2 65.5 15.6-25.6-93.6-55.5V152z"></path></g></svg>
                      </span>
                    </IconSpan>
                    <PooledSpan>{convertBigNumberToDate(bet.createdTime)}</PooledSpan>
                  </RowDiv>
                </Published>
                <Pooled>
                  <RowDiv>
                    <PooledSpan>{formatVolume(bet.volume)} pooled</PooledSpan>
                  </RowDiv>
                </Pooled>
              </GrStatsFlex>
            </GrStatsDiv>
          </GrDownFlex>
        </GrDown>
        </GridHolder>
        ))}
      </Grid>

      <Overlay isvisible={activeElement !== null} onClick={handleCloseClick} />

      <Slider isvisible={activeElement !== null}>
        {activeElement && (
          <>
            <SlHeader>
              <SlImageHolder>
                {activeElement.image && (
                  <SlImage src={activeElement.image} />
                )}
              </SlImageHolder>
              <MoreDetailsDiv>
                <h1>
                  <span>{activeElement.title}</span>
                </h1>
                <MoreDets1>
                  <MDLeft>
                    <p>{formatVolume(activeElement.volume)}</p>
                    <div>
                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g fillOpacity=".9"><path d="M255.8 48C141 48 48 141.2 48 256s93 208 207.8 208c115 0 208.2-93.2 208.2-208S370.8 48 255.8 48zm.2 374.4c-91.9 0-166.4-74.5-166.4-166.4S164.1 89.6 256 89.6 422.4 164.1 422.4 256 347.9 422.4 256 422.4z"></path><path d="M266.4 152h-31.2v124.8l109.2 65.5 15.6-25.6-93.6-55.5V152z"></path></g></svg>
                      <span>{convertBigNumberToDate(activeElement.createdTime)}</span>
                    </div>
                    <p>{activeElement.publishTime.toNumber() != 0 ? <>Resolved</> : <>{formatTime(activeElement.createdTime, activeElement.duration)}</>}</p>
                    {activeElement.eligibleAddresses.length > 0 ? (
                      <BBDiv>
                        <span>Buddy bet</span>
                      </BBDiv>
                    ) : <></>}
                  </MDLeft>
                  <MDRight>
                    <button onClick={handleCopy}>
                      <span>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" fontSize="22" className="c-kbjlOv" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.222 19.778c.975.975 2.255 1.462 3.535 1.462 1.281-.001 2.562-.487 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829c-1.169 1.167-3.072 1.169-4.243 0-1.169-1.17-1.169-3.073 0-4.243l2.829-2.828L7.051 9.879l-2.829 2.828C2.273 14.656 2.273 17.829 4.222 19.778zM19.778 11.293c1.948-1.949 1.948-5.122 0-7.071-1.95-1.95-5.123-1.948-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829c1.17-1.167 3.073-1.169 4.243 0 1.169 1.17 1.169 3.073 0 4.243l-2.829 2.828 1.414 1.414L19.778 11.293z"></path><path transform="rotate(-134.999 12 12)" d="M11 5.999H13V18H11z"></path></svg>
                      </span>
                    </button>
                  </MDRight>
                </MoreDets1>
              </MoreDetailsDiv>
              <CloseButonDiv>
                <CloseButton onClick={handleCloseClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" className="sc-60ffdd2d-0 eQTvdp"><path fill="var(--color-primary)" d="M20 2.014 17.986 0 10 7.986 2.014 0 0 2.014 7.986 10 0 17.986 2.014 20 10 12.014 17.986 20 20 17.986 12.014 10 20 2.014Z"></path></svg>
                </CloseButton>
              </CloseButonDiv>
            </SlHeader>
            <GrDown>
              {
                activeElement.optionsLen > 0 ? 
                (
                  <GrDownFlex>
                    <GrOptionFlex1>
                    {
                      loading ? <ButtonYes disabled><LoadingWhite /></ButtonYes> : <ButtonYes onClick={(e) => pushBetYes(e, activeElement.id, activeElement.optionsLen)} type="submit">
                      <ButtonYesFlex>
                        <span>Yes</span>
                      </ButtonYesFlex>
                    </ButtonYes>
                    }
                    <InputDiv>
                      <input type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="$0"
                        autoComplete="off"
                        title=""
                        value={value}
                        onChange={handleInputChange} />
                      <ToggleDivLeft>
                        <button onClick={decrementValue} aria-hidden="true">
                          <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.166672 0.166664H11.8333V1.83333H0.166672V0.166664Z" fill="var(--color-gray)"></path>
                          </svg>
                        </button>
                      </ToggleDivLeft>
                      <ToggleDivRight data-state="closed">
                        <button onClick={incrementValue} aria-hidden="true">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8333 5.16666H6.83333V0.166664H5.16667V5.16666H0.166668V6.83333H5.16667V11.8333H6.83333V6.83333H11.8333V5.16666Z" fill="var(--color-gray)"></path>
                          </svg>
                        </button>
                      </ToggleDivRight>
                    </InputDiv>
                    <InputDiv>
                      <select onChange={handleOptionChange}>
                        <option value="">Pick one</option>
                        {
                          activeElement.options?.map((option) => (
                            <option value={option} key={option}>{option}</option>
                          ))
                        }
                      </select>
                    </InputDiv>
                    </GrOptionFlex1>
                  </GrDownFlex>
                ) 
                : 
                (
                  <GrDownFlex>
                    <GrOptionFlex1>
                    {
                      loading ? <ButtonYes disabled><LoadingWhite /></ButtonYes> : <ButtonYes onClick={(e) => pushBetYes(e, activeElement.id, activeElement.optionsLen)} type="submit">
                      <ButtonYesFlex>
                        <span>Yes</span>
                      </ButtonYesFlex>
                    </ButtonYes>
                    }
                      <InputDiv>
                        <input type="text"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          placeholder="$0"
                          autoComplete="off"
                          title=""
                          value={value}
                          onChange={handleInputChange} />
                        <ToggleDivLeft>
                          <button onClick={decrementValue} aria-hidden="true">
                            <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.166672 0.166664H11.8333V1.83333H0.166672V0.166664Z" fill="var(--color-gray)"></path>
                            </svg>
                          </button>
                        </ToggleDivLeft>
                        <ToggleDivRight data-state="closed">
                          <button onClick={incrementValue} aria-hidden="true">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8333 5.16666H6.83333V0.166664H5.16667V5.16666H0.166668V6.83333H5.16667V11.8333H6.83333V6.83333H11.8333V5.16666Z" fill="var(--color-gray)"></path>
                            </svg>
                          </button>
                        </ToggleDivRight>
                      </InputDiv>
                      {
                        loading ? <ButtonNo disabled><LoadingWhite /></ButtonNo> : <ButtonNo 
                        onClick={(e) => pushBetNo(e, activeElement.id, activeElement.optionsLen)}
                        type="submit">
                        <ButtonNoFlex>
                          <span>No</span>
                        </ButtonNoFlex>
                      </ButtonNo>
                      }
                    </GrOptionFlex1>
                  </GrDownFlex>
                )
              }
            </GrDown>
            <PadDiv>
              <VolumeDiv>
                {volumeLoading ? <LoadingBlack></LoadingBlack> : <VolumeBars yesVolume={yesVolume} noVolume={noVolume} />}
              </VolumeDiv>
              <div>
                <BLogs>
                  <Blogs1>
                    <span>
                      Outcome
                    </span>
                  </Blogs1>
                  <Blogs2>
                    <Blogs2f>
                      <Blogs2p1>
                        {activeElement.outcome != '' ? activeElement.outcome.toString() : 'Pending'}
                        {activeElement.subject != "" && <span>, &nbsp;{activeElement.subject}</span>}
                      </Blogs2p1>
                      <Blogs2a>
                        <p>
                        {
                          activeElement.outcome !== '' 
                            ? formatPublishTime(activeElement.publishTime) 
                            : null
                        }
                        </p>
                      </Blogs2a>
                    </Blogs2f>
                  </Blogs2>
                </BLogs>
              </div>
              {
                activeElement.eligibleAddresses.length > 0 ? <div>
                <BLogs>
                  <Blogs1>
                    <span>
                      Buddy addresses
                    </span>
                  </Blogs1>
                  <WAddrDiv>
                    {
                      activeElement.eligibleAddresses?.map((addr) => (
                      <WAddr2f key={addr}>
                        <p>
                          {formatWalletAddress(addr)}
                        </p>
                      </WAddr2f>
                      ))
                    }  
                  </WAddrDiv>
                </BLogs>
              </div> : <></>
              }
              <BRulesDiv>
                <div>
                  <BRTopic>
                    <p>Rules</p>
                  </BRTopic>
                  <BrCopy>
                    <p>
                      Bets resolve once the outcome is proposed, verified and published. Once resolved, winners can claim winnings. Either side of "Yes" or "No" wins.
                      <br></br>
                      <br></br>
                      This bet will be resolved from live sources and with real-time data. In the case the outcome is neither "Yes" or "No", bettors get their bet amount back.
                    </p>
                  </BrCopy>
                  <BrResolver>
                    <ResolverFlex>
                      <ResolverHolder>
                        <div></div>
                        <AddressDiv>
                          <p>Publisher</p>
                          <Link to={`${polygonExplorerAddr}${publisher}`} target='_blank'>{formatWalletAddress(publisher)}</Link>
                        </AddressDiv>
                      </ResolverHolder>
                    </ResolverFlex>
                  </BrResolver>
                </div>
              </BRulesDiv>
              <TabButtons>
                <TabButton isActive={activeTab === 'comments'} onClick={() => setActiveTab('comments')}>
                  Comments
                </TabButton>
                {/* <TabButton isActive={activeTab === 'history'} onClick={() => setActiveTab('history')}>
                  History
                </TabButton> */}
              </TabButtons>
              <TabContent>
                {renderContent(activeElement.id.toNumber())}
              </TabContent>
              <NotificationMessage message={message} show={showNotification}></NotificationMessage>
            </PadDiv>
          </>
          )
        }
      </Slider>
    </OuterDiv>
  )
}

export default BetGrid;
