import React, { useRef, useEffect } from 'react';

const VolumeBars = ({ yesVolume, noVolume }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (yesVolume === 0 && noVolume === 0) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const totalVolume = yesVolume + noVolume;
    
    const yesPercentage = (yesVolume / totalVolume) * 100;
    const noPercentage = (noVolume / totalVolume) * 100;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = '#101010';
    ctx.font = '12px Market Sans';
    const percentages = [0, 25, 50, 75, 100];
    percentages.forEach((percentage) => {
      const y = (canvas.height / 100) * (100 - percentage);
      ctx.fillText(`${percentage}%`, canvas.width - 30, y);
    });

    const maxBarHeight = canvas.height - 20;
    const yesBarHeight = (maxBarHeight * yesPercentage) / 100;
    const noBarHeight = (maxBarHeight * noPercentage) / 100;

    const barWidth = 30;

    ctx.fillStyle = '#27AE60';
    ctx.fillRect(70, canvas.height - yesBarHeight, barWidth, yesBarHeight);

    ctx.fillStyle = '#E64800';
    ctx.fillRect(150, canvas.height - noBarHeight, barWidth, noBarHeight);
  }, [yesVolume, noVolume]);

  if (yesVolume === 0 && noVolume === 0) {
    return <p>No volume yet</p>;
  }

  return <canvas ref={canvasRef} width={300} height={200} position="absolute" z-index={2} left={0} top={0} />;
};

export default VolumeBars;