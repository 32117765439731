import React, { useState } from 'react';
import styled from 'styled-components';
import { ethers } from 'ethers';
import NotificationMessage from './NotificationMessage';
import { contractAddr } from '../config';
import Stake from "../utils/abi.json"
import LoadingWhite from './LoadingWhite';

const TabC1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 24px;
`

const CommentBox = styled.div`
  align-items: center;
  padding-bottom: 1.6rem;
  display: flex;
`

const CommentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const CInputDiv = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`

const InputEl = styled.input`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease 0s;
  cursor: text;
  outline: none;
  color: var(--color-primary);
  font-size: inherit;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  border: 1px solid #E0E0E0;
  height: 100%;
  box-sizing: border-box;
  background: none;
  font-family: inherit;
  font-weight: 400;
`

const InputEl1 = styled.input`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease 0s;
  cursor: text;
  outline: none;
  color: var(--color-primary);
  font-size: inherit;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  border: 1px solid #E0E0E0;
  height: 100%;
  box-sizing: border-box;
  background: none;
  font-family: inherit;
  max-width: 200px;
  font-weight: 400;
`

const InputButton = styled.button`
  background: var(--color-purple);
  height: 44px;
  font-size: 1.4rem;
  border: none;
  border-radius: 8px;
  color: var(--color-white);
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: inherit;
  user-select: none;
  padding: 0 1.2rem;
  max-width: 200px;

  &:disabled {
    cursor: not-allowed;
  }
`

const CreateBuddyBet = ({currentWallet}) => {
  const [text, setText] = useState('');
  const [duration, setDuration] = useState(0);
  const [image, setImage] = useState('');
  const [addresses, setAddresses] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleBetNotifId = async(text) => {
    setMessage(text);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  }

  const handleTextChange = (e) => {
    setText(e.target.value);
  }

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  }

  const handleImageChange = (e) => {
    setImage(e.target.value);
  }

  const handleAddressesChange = (e) => {
    setAddresses(e.target.value);
  };

  const isValidEthereumAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  const handleSubmit = async () => {
    if (text == '') {
      alert("Please type a valid bet subject!");
      return;
    }
    if (duration == 0) {
      alert("Please enter a valid duration in seconds!");
      return;
    }
    if (image == '') {
      alert("Please provide a valid image URL!");
      return;
    }
    if (!currentWallet) {
      alert("Please connect a wallet to create bets!");
      return;
    }

    const addressArray = addresses.split(',').map(addr => addr.trim());
    const uniqueAddresses = [...new Set(addressArray)];

    for (let address of uniqueAddresses) {
      if (!isValidEthereumAddress(address)) {
        alert(`Invalid Ethereum address detected: ${address}`);
        return;
      }
    }

    if (uniqueAddresses.length === 0) {
      alert("Please provide at least one unique address!");
      return;
    }

    try {
      const {ethereum} = window;

      if (ethereum) {
        setLoading(true);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const StakeContract = new ethers.Contract(
          contractAddr,
          Stake.abi,
          signer
        )
        
        let newBuddyBet = await StakeContract.createBuddyBet(text, image, duration, uniqueAddresses);
        const buddyBetReceipt = await newBuddyBet.wait();
        setLoading(true);

        if (buddyBetReceipt.status === 1) {
          handleBetNotifId('Bet created');
          setText('');
          setDuration(0);
          setImage('');
          setAddresses('');
        } else {
          setText('');
          setDuration(0);
          setImage('');
          setAddresses('');
          handleBetNotifId('Bet creation failed');
        }

      } else {
        console.log("Ethereum object doesn't exist");
      }

    } catch (error) {
      console.log("Error submitting bet", error);
    }
    
  };

  return (
    <TabC1>
      <CommentBox>
        <CommentDiv>
          <CInputDiv>
            <InputEl 
              placeholder="HAM joins Ferrari 2025?" autoComplete="disabled" 
              type="text"
              value={text}
              onChange={handleTextChange}
            ></InputEl>
            <InputEl 
              placeholder="https://imagelink.com" autoComplete="disabled" 
              type="text"
              value={image}
              onChange={handleImageChange}
            ></InputEl>
          </CInputDiv>
          <CInputDiv>
            <InputEl 
              placeholder="123456" autoComplete="disabled" 
              type="text"
              value={duration}
              onChange={handleDurationChange}
            ></InputEl>
            <InputEl 
              placeholder="0xb88b48ef1Ac9814e3fc676DE447c5fbce25049AA, 0xc9c87b866D0850B5184e2B1F18E60c6E4eFbE430" autoComplete="disabled" 
              type="text"
              value={addresses}
              onChange={handleAddressesChange}
            ></InputEl>
          </CInputDiv>
          {loading ? <InputButton disabled><LoadingWhite /></InputButton> : <InputButton onClick={handleSubmit}>Create</InputButton>}
        </CommentDiv>
      </CommentBox>
      <NotificationMessage message={message} show={showNotification}></NotificationMessage>
    </TabC1>
  )
}

export default CreateBuddyBet;