import {styled} from "styled-components";
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import All from '../components/All';
import Open from '../components/Open';
// import Claimed from '../components/Claimed';
import {ethers} from 'ethers';
import { contractAddr } from "../config";
import Stake from '../utils/abi.json'
import JazziconProfile from "../components/JazziconProfile";
import CreateBuddyBet from "../components/CreateBuddyBet";
import ProgressBarComponent from "../components/Progress";
import Expired from "../components/Expired";

const Header = styled.header`
  padding-inline: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  // @media (max-width: 420px) {
  //   min-height: 150px;
  // }
`

const HeaderDiv = styled.div`
  display: grid;
  gap: 36px;
  max-width: var(--page-layout);
  margin-inline: auto;
`

const GridEl1 = styled.div`
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
`

const GLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  font-weight: 400;
`

const GLIconDiv = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  gap: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const GlTextDiv = styled.div`
  color: inherit;
`

const GRight = styled.div`
  font-size: 1.4rem;
  font-weight: 400;

  & a {
    text-decoration: none;
    font-family: inherit;
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    transition: opacity 0.3s;
  }

  & span {
    color: hsl( 255 3% 69% /1);
  }
`

const GRButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  min-width: 100px;
  width: 100%;
  padding-inline: 15px;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  color: inherit;
  background: none;
  transition: filter 0.3s;
  border: none;
  border-bottom: 1px solid;
  outline none;
  cursor: pointer;


  &:hover {
    background: transparent;
    border-color: var(--color-purple);
  }
`

const SphereAvatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.color};
  display: inline-block;
  margin-right: 8px;
`;

const AvatarDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const ProfileDiv = styled.div`
  display: flex;
  background-color: var(--color-white);
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 1.5rem;

  @media (min-width: 1018px) {
    padding-top: 3.8rem;
    padding-bottom: 0px;
    overscroll-behavior: auto;
    height: calc(-64px + 100vh);
    overflow-y: auto;
  }
`

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1018px;
  height: 100%;
  align-self: center;
`

const Portfolio = styled.div`
  border-radius: 1.2rem;
  background: rgb(127, 85, 217, 0.1);
  gap: 1.6rem;
  margin-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;

  @media (min-width: 1018px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2.4rem 2.8rem;
  }
`

const PortFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: .8rem;
`

const PortFlexHeader = styled.div`
  display: flex;

  & p {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-weight: 400;
    font-size: 1.4rem;
    text-decoration: none;
    vertical-align: middle;
  }
`

const PortFlexDigits = styled.div`
  display: flex;
  transition: all 0.2s ease 0s;

  & p {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-weight: 500;
    text-decoration: none;
    color: inherit;
    font-size: 3.2rem;

    @media (max-width: 450px) {
      font-size: 2rem;
    }
  }
`

const PortFlexDivider = styled.div`
  background-color: rgb(210, 212, 221);
  display: flex;
  width: 1px;
  align-items: stretch;
`

const Portfolio2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TabButtons = styled.div`
  display: flex;
  border-bottom: 1px solid #F2F2F2;
  margin-top: 3.2rem;
`;

const TabButton = styled.button`
  margin-right: 3.2rem;
  padding-bottom: 1.5rem;
  font-family: inherit;
  color: #2b2b2b;
  font-size: 1.6rem;
  border-bottom: 2px solid #2b2b2b;
  font-weight: 600;
  outline: none;
  border: none;
  border-bottom: ${({ isActive }) => (isActive ? '1px solid #2b2b2b' : '')};
  cursor: pointer;
  background: none;
`;

const TabContent = styled.div`
  width: 100%;
  padding-top: 2.4rem 0;
  overflow: hidden;
`;

const Profile = ({currentNetwork, currentWallet, connectWallet}) => {
  const [activeTab, setActiveTab] = useState('all');
  const [userBets, setUserBets] = useState([]);
  const [userVolume, setUserVolume] = useState(0);
  const [userBetCount, setUserBetCount] = useState(0);
  const [userPnl, setUserPnl] = useState(0);
  const [loading, setLoading] = useState(false);

  const decimals = 6;
  const formatVolume = (amount) => {
    const amountInSmallestUnit = ethers.BigNumber.from(amount);
    const value = ethers.utils.formatUnits(amountInSmallestUnit, decimals);
    return `$${Number(value).toFixed(2)}`;
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'all':
        return <All userBets={userBets} formatVolume={formatVolume} currentWallet={currentWallet} loading={loading} />;
      case 'open':
        return <Open userBets={userBets} formatVolume={formatVolume} currentWallet={currentWallet} loading={loading} />;
      case 'expired':
        return <Expired userBets={userBets} formatVolume={formatVolume} currentWallet={currentWallet} loading={loading} />;
      // case 'claimed':
      //   return <Claimed userBets={userBets} formatVolume={formatVolume} currentWallet={currentWallet} loading={loading} />;
      case 'buddy':
        return <CreateBuddyBet currentWallet={currentWallet} />;
      default:
        return <All userBets={userBets} formatVolume={formatVolume} currentWallet={currentWallet} loading={loading}/>;
    }
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const generateColorFromAddress = (address) => {
    let hash = 0;
    for (let i = 0; i < address.length; i++) {
      hash = address.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  const queryUserBets = async() => {
    try {
      const {ethereum} = window

      if (ethereum) {
        ProgressBarComponent.start();
        setLoading(true);

        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const StakeContract = new ethers.Contract(
          contractAddr,
          Stake.abi,
          signer
        )

        let allUserBets = await StakeContract.getUserBets(currentWallet);
        let userV = await StakeContract.userVolume(currentWallet);
        let userPnl = await StakeContract.getUserPnl(currentWallet);
        let userBetC = await StakeContract.userBetCount(currentWallet);
        setUserBets(allUserBets);
        setUserVolume(userV);
        setUserPnl(userPnl);
        setUserBetCount(userBetC);
        ProgressBarComponent.finish();
        setLoading(false);
      } else {
        console.log("Ethereum object doesn't exist");
      }
    } catch(error) {
      console.log(error);
      ProgressBarComponent.finish();
      setLoading(false);
    }
  }

  useEffect(() => {
    queryUserBets();
  }, [currentWallet]);


  return (
    <div>
      <Header>
        <HeaderDiv>
          <GridEl1>
            <GLeft>
              <GlTextDiv>
                <span>
                  <Link to="/">Back home</Link>
                </span>
              </GlTextDiv>
            </GLeft>
            <GRight>
              {currentWallet === '' ? (
                <GRButton
                onClick={connectWallet}>
                  Connect wallet
                </GRButton>
                ) : currentNetwork ? (
                  <Link to="/profile">
                    <AvatarDiv>
                      <p>{formatWalletAddress(currentWallet)}</p>
                      <JazziconProfile address={currentWallet} width={30} height={30} />
                    </AvatarDiv>
                  </Link>
                ) : (
                <div>
                  Switch to Matic Network
                </div>
              )}
            </GRight>
          </GridEl1>
        </HeaderDiv>
      </Header>
      <ProfileDiv>
        <ProfileContainer>
          <Portfolio>
            <PortFlex>
              <PortFlexHeader>
                <p>PnL</p>
              </PortFlexHeader>
              <PortFlexDigits>
                <p>{formatVolume(userPnl)}</p>
              </PortFlexDigits>
            </PortFlex>
            <PortFlexDivider></PortFlexDivider>
            <PortFlex>
              <PortFlexHeader>
                <p>Volume</p>
              </PortFlexHeader>
              <PortFlexDigits>
                <p>{formatVolume(userVolume)}</p>
              </PortFlexDigits>
            </PortFlex>
            <PortFlexDivider></PortFlexDivider>
            <PortFlex>
              <PortFlexHeader>
                <p>Total bets</p>
              </PortFlexHeader>
              <PortFlexDigits>
                <p>{userBetCount.toString()}</p>
              </PortFlexDigits>
            </PortFlex>
          </Portfolio>
          <Portfolio2>
          <TabButtons>
            <TabButton isActive={activeTab === 'all'} onClick={() => setActiveTab('all')}>
              All
            </TabButton>
            <TabButton isActive={activeTab === 'open'} onClick={() => setActiveTab('open')}>
              Open
            </TabButton>
            <TabButton isActive={activeTab === 'expired'} onClick={() => setActiveTab('expired')}>
              Expired
            </TabButton>
            {/* <TabButton isActive={activeTab === 'claimed'} onClick={() => setActiveTab('claimed')}>
              Claimed
            </TabButton> */}
            <TabButton isActive={activeTab === 'buddy'} onClick={() => setActiveTab('buddy')}>
              Buddy tab
            </TabButton>
          </TabButtons>
          <TabContent>
            {renderContent()}
          </TabContent>
          </Portfolio2>
        </ProfileContainer>
      </ProfileDiv>
      <Footer />
    </div>
  )
}

export default Profile;
