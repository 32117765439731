import React, { useEffect, useRef } from 'react';
import jazzicon from 'jazzicon';

const generateJazzicon = (address, diameter) => {
  const seed = parseInt(address.slice(2, 10), 16);
  return jazzicon(diameter, seed);
};

const JazziconProfile = ({ address, width = 50, height = 50 }) => {
  const iconRef = useRef(null);

  useEffect(() => {
    if (iconRef.current && address) {
      const icon = generateJazzicon(address, Math.min(width, height));
      icon.style.width = `${width}px`;
      icon.style.height = `${height}px`;
      iconRef.current.innerHTML = '';
      iconRef.current.appendChild(icon);
    }
  }, [address, width, height]);

  return <div ref={iconRef} />;
};

export default JazziconProfile;