import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import JazziconProfile from './JazziconProfile';

const OutderDiv = styled.div`
  width: 90%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
`

const WrapperDiv = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 5px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;
  }
`

const ElementDiv = styled.div`
  color: var(--color-primary);
  margin-left: auto;
  margin-right: auto;

  & h2 {
    text-align: center;
  }
`

const ElementDivFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  margin: 1rem;
`

const ElementOne = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  text-align: left;
  gap: .5rem;
  min-width: 0px;

  & a {
    color: inherit;
    text-decoration: none;
  }
`

const ElementOnePfp = styled.div`
  position: relative;
`

const ElementOneP = styled.p`
  flex: 1 1 0%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ElementTwo = styled.div`
  min-width: 50px;
  text-align: right;
  flex-shrink: 0;

  & a {
    color: inherit;
    text-decoration: none;
  }
`

const Activity = ({ contractAddress, apiKey }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}`;
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`https://api.polygonscan.com/api`, {
          params: {
            module: 'account',
            action: 'txlist',
            address: contractAddress,
            startblock: 0,
            endblock: 99999999,
            sort: 'desc',
            apikey: apiKey,
            offset: 5,
            page: 1
          }
        });

        const allTransactions = response.data.result.slice(0, 10);
        setTransactions(allTransactions);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching transactions', error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [contractAddress, apiKey]);

  if (loading) {
    return <OutderDiv>Loading...</OutderDiv>;
  }

  return (
    <OutderDiv>
      <WrapperDiv>
        <ElementDiv>
        <h2>Recent activity</h2>
        {transactions?.map(tx => (
          <ElementDivFlex key={tx.hash}>
            <ElementOne>
              <ElementOnePfp>
                <JazziconProfile address={tx.from} width={20} height={20} /> 
              </ElementOnePfp>
              <Link to={`https://polygonscan.com/tx/${tx.hash}`} target='_blank'>{formatWalletAddress(tx.from)}</Link>
              <ElementOneP>
              {tx.functionName.includes("placeBet") && <>placed a bet</>}
              {tx.functionName.includes("claimWinning") && <>claimed winnings</>}
              {tx.functionName.includes("createBet") && <>created a bet</>}
              {tx.functionName.includes("createBuddyBet") && <>started a buddy bet</>}
              {tx.functionName.includes("resolveBet") && <>resolved a bet</>}
              {tx.functionName.includes("setBetInitiator") && <>set a bet initiator</>}
              {tx.functionName.includes("acceptBetInitiatorRole") && <>accepted initiator role</>}
              {tx.functionName.includes("claimFee") && <>claimed fees</>}
              {tx.functionName.includes("setBetToken") && <>set a bet token</>}
              {tx.functionName.includes("setFee") && <>set a new bet fee</>}
              {tx.functionName.includes("setFeeRecipient") && <>set fee recipient</>}
              {tx.functionName.includes("setPublisher") && <>set a bet resolver</>}
              {tx.functionName.includes("acceptPublishingRole") && <>accepted resolver role</>}
              </ElementOneP>
            </ElementOne>
            <ElementTwo>
              <Link to={`https://polygonscan.com/tx/${tx.hash}`} target='_blank'>{formatDistanceToNow(new Date(tx.timeStamp * 1000))} ago</Link>
            </ElementTwo>
          </ElementDivFlex>
          ))
        }
        </ElementDiv>
      </WrapperDiv>
    </OutderDiv>
  );
};

export default Activity;
