import {styled} from "styled-components";
import BetGrid from "../components/BetGrid";
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
// import ThemeToggle from '../components/ThemeToggle';
import {ethers} from 'ethers';
import { contractAddr } from '../config';
import Stake from '../utils/abi.json'
import { useEffect, useState } from 'react';
import Disclaimer from '../components/Disclaimer';
import SkeletonCard from "../components/SkeletonCard";
import ProgressBarComponent from "../components/Progress";
import JazziconProfile from "../components/JazziconProfile";
import Activity from "../components/Activity";

const Header = styled.header`
  min-height: 250px;
  // background: hsl( 280 4% 15% /1);
  padding-inline: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  @media (max-width: 420px) {
    min-height: 150px;
  }
`

const HeaderDiv = styled.div`
  display: grid;
  gap: 36px;
  max-width: var(--page-layout);
  margin-inline: auto;
`

const GridEl1 = styled.div`
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // color: hsl( 255 3% 69% /1);
  background-position-x: ;
  background-position-y: ;
  background-attachment: ;
  background-origin: ;
  background-clip: ;
  background-color: ;
  background-image: url(/assets/black-lines.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
`

const GridElLogo = styled.div`
  display: block;
  max-width: 75px;
  max-height: 75px;
  font-weight: 500;
  font-size: 2.4rem;

  & img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 500px) {
    max-width: 50px;
    max-height: 50px;
  }
`

const GLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  font-weight: 400;
`

const GLIconDiv = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  gap: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const GlTextDiv = styled.div`
  color: inherit;

  & a {
    color: var(--color-purple);
  }
`

const GRight = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
  font-weight: 400;

  & a {
    text-decoration: none;
    font-family: inherit;
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    transition: opacity 0.3s;
  }

  & span {
    color: hsl( 255 3% 69% /1);
  }
`

const GRButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  min-width: 100px;
  width: 100%;
  padding-inline: 15px;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  color: inherit;
  background: none;
  transition: filter 0.3s;
  border: none;
  border-bottom: 1px solid;
  outline none;
  cursor: pointer;


  &:hover {
    background: transparent;
    border-color: var(--color-purple);
  }
`

const GridEl2 = styled.div`
  display: flex;
  align-items: center;
  gap: clamp(0.5rem, 0.17rem + 1.63vw, 1.5rem);
  font-weight: 500;
`

const GridEl2Heading = styled.div`
  & p {
    max-width: 592px;
    margin-top: 4px;
    margin-left: 4px;
    font-size: 1.5rem;
  }

  & a {
    margin-top: 4px;
    margin-left: 4px;
    text-decoration: none;
    font-family: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    transition: opacity 0.3s;
    color: var(--color-purple);
  }
`

const H1 = styled.h1`
  font-size: clamp(2.5rem, 3rem + 2.5vw, 6.4rem);

  @media (max-width: 420px) {
    font-size: clamp(2.5rem, 1.5rem + 0.5vw, 6.4rem);
  }

  & strong {
    font-weight: inherit;
    color: var(--color-purple);
  }
`

const SphereAvatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.color};
  display: inline-block;
  margin-right: 8px;
`;

const AvatarDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const Home = ({currentNetwork, currentWallet, connectWallet, toggleTheme, theme}) => {
  const [bets, setBets] = useState([]);
  const [publisher, setPublisher] = useState('');
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let disclaimerStatus = localStorage.getItem('disclaimer_status');
    if (!disclaimerStatus) {
      setTimeout(() => {
        setOpenDisclaimer(true);
      }, 5000);
      localStorage.setItem('disclaimer_status', 1);
    }
  }, [])

  const queryAllBets = async () => {
    try {
      ProgressBarComponent.start();
      setLoading(true);

      const provider = new ethers.providers.JsonRpcProvider(`https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA}`);
      const StakeContract = new ethers.Contract(contractAddr, Stake.abi, provider);
  
      let allBets = await StakeContract.getAllBetsCreated();
      let currPublisher = await StakeContract.publisher();
      setPublisher(currPublisher);

      allBets = allBets.map(bet => ({
        ...bet,
        createdTime: ethers.BigNumber.from(bet.createdTime)
      }));
      allBets.sort((a, b) => b.createdTime.sub(a.createdTime));

      ProgressBarComponent.finish();
      setLoading(false);

      return (allBets);
    } catch (error) {
      console.log(error);
      ProgressBarComponent.finish();
      setLoading(false);
    }
  }

  const updateBet = (updatedBet) => {
    setBets((prevBets) =>
      prevBets.map((bet) =>
        bet.id.toNumber() === updatedBet.id.toNumber() ? updatedBet : bet
      )
    );
  };

  useEffect(() => {
    const fetchBets = async () => {
      const allBets = await queryAllBets();
      setBets(allBets);
    };

    fetchBets();
  }, []);

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const generateColorFromAddress = (address) => {
    let hash = 0;
    for (let i = 0; i < address.length; i++) {
      hash = address.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  if (loading) {
    return (
      <div>
      <Header>
        <HeaderDiv>
          <GridEl1>
            <GLeft>
              <GLIconDiv>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" className=""><path stroke="var(--color-purple)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8 14.667A6.667 6.667 0 1 0 8 1.334a6.667 6.667 0 0 0 0 13.333Z"></path><path stroke="var(--color-purple)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8 4v4l2.667 1.333"></path></svg>
              </GLIconDiv>
              <GlTextDiv>
                <span>
                  <Link to={`https://polygonscan.com/address/${contractAddr}`} target="_blank">
                    Onchain data
                  </Link>
                </span>
              </GlTextDiv>
            </GLeft>
            <Link to="/">
              <GridElLogo>
                <img src='sp2p-purple.png' alt='logo' />
              </GridElLogo>
            </Link>
            <GRight>
              {currentWallet === '' ? (
                <GRButton
                onClick={connectWallet}>
                  Connect wallet
                </GRButton>
                ) : currentNetwork ? (
                  <Link to="/profile">
                    <AvatarDiv>
                      <p>{formatWalletAddress(currentWallet)}</p>
                      <JazziconProfile address={currentWallet} width={30} height={30} />
                    </AvatarDiv>
                  </Link>
                ) : (
                <div>
                  Switch to Matic Network
                </div>
              )}
            </GRight>
          </GridEl1>
          <GridEl2>
            <GridEl2Heading>
              <H1>
                <strong>{bets.length}</strong> total bets
              </H1>
              <p>Resolved stakes to date</p>
              <Link to={`https://polygonscan.com/address/${contractAddr}`} target="_blank">
                <span>Block explorer</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 10 10" className=""><path stroke="var(--color-purple)" strokeWidth="1.5" d="M.757.757h8.486m0 0v8.486m0-8.486L.757 9.243"></path></svg>
              </Link>
            </GridEl2Heading>
          </GridEl2>
        </HeaderDiv>
      </Header>
      
      <SkeletonCard />

      <Disclaimer open={openDisclaimer} onClose={() => setOpenDisclaimer(false)} />
      <Footer />
    </div>
    )
  }

  return (
    <div>
      <Header>
        <HeaderDiv>
          <GridEl1>
            <GLeft>
              <GLIconDiv>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" className=""><path stroke="var(--color-purple)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8 14.667A6.667 6.667 0 1 0 8 1.334a6.667 6.667 0 0 0 0 13.333Z"></path><path stroke="var(--color-purple)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8 4v4l2.667 1.333"></path></svg>
              </GLIconDiv>
              <GlTextDiv>
                <span>
                  <Link to={`https://polygonscan.com/address/${contractAddr}`} target="_blank">
                    Onchain data
                  </Link>
                </span>
              </GlTextDiv>
            </GLeft>
            <Link to="/">
              <GridElLogo>
                <img src='sp2p-purple.png' alt='logo' />
              </GridElLogo>
            </Link>
            <GRight>
              {currentWallet === '' ? (
                <GRButton
                onClick={connectWallet}>
                  Connect wallet
                </GRButton>
                ) : currentNetwork ? (
                  <Link to="/profile">
                    <AvatarDiv>
                      <p>{formatWalletAddress(currentWallet)}</p>
                      <JazziconProfile address={currentWallet} width={30} height={30} />
                    </AvatarDiv>
                  </Link>
                ) : (
                <div>
                  Switch to Matic Network
                </div>
              )}
              {/* <ThemeToggle toggleTheme={toggleTheme} theme={theme} /> */}
            </GRight>
          </GridEl1>
          <GridEl2>
            <GridEl2Heading>
              <H1>
                <strong>{bets.length}</strong> total bets
              </H1>
              <p>Resolved stakes to date</p>
              <Link to={`https://polygonscan.com/address/${contractAddr}`} target="_blank">
                <span>Block explorer</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 10 10" className=""><path stroke="var(--color-purple)" strokeWidth="1.5" d="M.757.757h8.486m0 0v8.486m0-8.486L.757 9.243"></path></svg>
              </Link>
            </GridEl2Heading>
          </GridEl2>
        </HeaderDiv>
      </Header>
      <BetGrid bets={bets} currentWallet={currentWallet} publisher={publisher} updateBet={updateBet}/>
      <Activity contractAddress={contractAddr} apiKey={process.env.REACT_APP_POLYGONSCANAPIKEY} />
      <Disclaimer open={openDisclaimer} onClose={() => setOpenDisclaimer(false)} />
      <Footer />
    </div>
  )
}

export default Home;
