import React, { useState } from 'react';
import styled from 'styled-components';
import { ethers, BigNumber } from 'ethers';
import { contractAddr } from '../config';
import Stake from '../utils/abi.json';
import NotificationMessage from './NotificationMessage';

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  padding-bottom: 1.6rem;
`

const FlexInner = styled.div`
  display: flex;
  flex-direction: column;
`

const BetsDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const BetItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const BetItem = styled.div`
  width: 100%;
  height: 100%;
  gap: 1.2rem;
  display: flex;
  align-items: flex-start;
  background-color: var(--color-white);
  transition: all 0.2s ease 0s;
  padding-top: 1.5rem;
  border-bottom: 1px solid #F2F2F2;
  flex-wrap: wrap;
`

const TitleHolder = styled.div`
  flex: 5.75 1 0%;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;
  max-width: 454px;
  display: flex;
  // flex-wrap: wrap;
`

const Div1 = styled.div`
  display: flex;

  & div {
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding-left: 1px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    display: block;
  }

  & img {
    object-fit: cover;
    max-width: 100%;
  }
`

const Div2 = styled.div`
  display: flex;
  flex-direction: column;

  & p {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-size: 1.4rem;
    font-weight: 600;
    flex: 1 1 0%;
    color: inherit;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-height: 2rem;
    -webkit-line-clamp: 1;
    max-width: 390px;
  }
`

const TimeAndSide = styled.div`
  align-items: center;
  gap: 0.8rem;
  padding-top: 0.8rem;
  display: flex;
`

const Side = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 4px;
`

const Time = styled.div`
  color: #828282;
  font-size: 1.2rem;
`

const BAmount = styled.div`
  font-size: 1.2rem;
  display: none;

  @media (max-width: 450px) {
    display: block;
  }
`

const BClaim = styled.button`
  font-size: 1.2rem;
  display: none;
  border: none;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
  background: none;
  text-decoration: underline;

  @media (max-width: 450px) {
    display: block;
  }
`

const AmountHolder = styled.div`
  flex: 0.85 1 0%;
  align-items: center;
  justify-content: flex-start;
  display: none;

  & p {
    margin: 0px;
    transition: all 0.2s ease 0s;
    font-weight: 400;
    color: inherit;
    font-weight: 1.4rem;
  }

  @media (min-width: 451px) {
    display: flex;
  }
`

const ClaimButton = styled.button`
  flex: 1.5 1 0%;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;
  text-align: center;
  border: none;
  border-radius: 7px;
  transition: all 0.2s ease 0s;
  padding: 0.9rem 1.8rem;
  user-select: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 1.4rem;
  background-color: var(--color-purple);
  color: var(--color-white);
  height: 4rem;
  gap: 4px;
  max-width: 100px;

  @media (min-width: 451px) {
    display: flex;
  }
`

const Expired = ({userBets, formatVolume, currentWallet, loading}) => {

  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState('');

  const convertBigNumberToDate = (bigNumber) => {
    const bn = BigNumber.isBigNumber(bigNumber) ? bigNumber : BigNumber.from(bigNumber);
    const timestamp = bn.toNumber();
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  const handleBetNotifId = async(text) => {
    setMessage(text);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  }

  const expiredBet = (createdTime, duration) => {
    const createdTimeInSeconds = ethers.BigNumber.from(createdTime).toNumber();
    const durationInSeconds = ethers.BigNumber.from(duration).toNumber();
    const expirationTimeInSeconds = createdTimeInSeconds + durationInSeconds;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  
    return currentTimeInSeconds > expirationTimeInSeconds ? true : false;
  };

  const claimBet = async (e, id) => {
    e.preventDefault();

    try {
      const {ethereum} = window

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const StakeContract = new ethers.Contract(
          contractAddr,
          Stake.abi,
          signer
        )

        let claimWinTxn = await StakeContract.claimWinning(id);
        const receipt = await claimWinTxn.wait();

        if (receipt.status === 1) {
          handleBetNotifId('Winning claimed');
        } else {
          handleBetNotifId('Claim failed');
        }
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (err) {
      console.log(err.toString());
      if (err.toString().includes('Already claimed')) {
        handleBetNotifId('Failed: Already claimed');
      }
      if (err.toString().includes('Bet not yet resolved')) {
        handleBetNotifId('Failed: Not yet resolved');
      }
    }
  }

  if (loading) {
    return (
      <FlexDiv>Loading...</FlexDiv>
    )
  }

  if (userBets.length == 0) {
    return (
      <FlexDiv>
        <p>No expired bets</p>
      </FlexDiv>
    )
  }

  return (
    <>
      <FlexDiv>
        <FlexInner>
          <BetsDiv>
            <BetItemHolder>
            {userBets
              ?.filter((bet) => expiredBet(bet.timestamp, bet.expiry))
              .map((bet) => (
                <BetItem key={bet.betId.toNumber()}>
                  <TitleHolder>
                    <Div1>
                      <div>
                        <img src={bet.image} alt="Bet" />
                      </div>
                    </Div1>
                    <Div2>
                      <p>{bet.text}</p>
                      <TimeAndSide>
                        <Side>
                        {bet.side}
                        {bet.subject != "" && <>, &nbsp;{bet.subject}</>}
                        </Side>
                        <Time>{convertBigNumberToDate(bet.timestamp)}</Time>
                        <BAmount>{formatVolume(bet.amount)}</BAmount>
                        <BClaim onClick={(e) => claimBet(e, bet.betId)} type='submit'>Claim</BClaim>
                      </TimeAndSide>
                    </Div2>
                  </TitleHolder>
                  <AmountHolder>
                    <p>{formatVolume(bet.amount)}</p>
                  </AmountHolder>
                  <ClaimButton onClick={(e) => claimBet(e, bet.betId)} type='submit'>
                    Claim
                  </ClaimButton>
                </BetItem>
            ))}
            </BetItemHolder>
          </BetsDiv>
        </FlexInner>
      </FlexDiv>
      <NotificationMessage message={message} show={showNotification}></NotificationMessage>
    </>
  )
}

export default Expired;