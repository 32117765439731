import React, { useEffect } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

const NotFoundContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 80px;
  margin: 8rem auto;

  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
`

const NotFoundEmpty = styled.div`
`

const NotFoundEmptyFlex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2.5%;
  padding-top: 2.5%;
`

const NotFoundHeaderOne = styled.h1`
  margin-bottom: 48px;
  font-weight: 400;
  text-align: center;
  font-size: 35px;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <NotFoundContainer>
        <NotFoundEmpty>
          <NotFoundEmptyFlex>
            <NotFoundHeaderOne>Oops! This page seems to have run away.</NotFoundHeaderOne>
          </NotFoundEmptyFlex>
        </NotFoundEmpty>
      </NotFoundContainer>
      <Footer />
    </>
  )
}

export default NotFound;